import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/ko";
import StarRating from "../../components/StartRating";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Avatar } from "@material-ui/core";

export default function OfflineEducationDetail({
  match: {
    params: { bid, id },
  },
}) {
  const [eduData, setEduData] = useState();
  const router = useHistory();
  useEffect(() => {
    const getEduData = async () => {
      const [eduDataResult, reviewDataResult] = await Promise.all(
        [
          { url: "/education/one", params: { id: bid } },
          { url: "/badge/review", params: { bid, page: 0, count: 3 } },
        ].map((urls) => {
          const fnc = async () =>
            await axios.post(
              `https://api.teamstc.org:3003/api/user${urls.url}`,
              urls.params
            );
          return fnc();
        })
      );
      if (!eduDataResult?.data?.success || !reviewDataResult?.data?.success)
        return;
      const data = eduDataResult.data;
      setEduData({
        classInfo: data.edu.filter((x) => x.id === id)[0],
        info: {
          ...data.info,
          etc: [
            data.info.category,
            data.info.title,
            data.info.write_yn === "Y" ? "이론시험" : "",
            data.info.prac_yn === "Y" ? "실기시험" : "",
          ],
        },
        reviews: reviewDataResult.data.rows,
      });
    };
    getEduData();
  }, []);

  const goDownloadApp = () => {
    const isMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) return true;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return true;
      return window.innerWidth <= 768;
    };
    if (isMobile())
      window.location.href =
        "https://api.teamstc.org:3003/appgate?id=/user/education/" + bid;
    else router.push("/appintoduce");
  };

  return (
    <div className="min-h-[60vh] p-3 max-w-[1000px] mx-auto">
      {eduData && (
        <div>
          <div>
            <p className="text-center bg-[#1D4161] text-white p-3 text-2xl rounded-t-3xl md:rounded-t-full">
              {eduData.classInfo.eduname}
            </p>
            <div className="p-3 mx-auto max-w-[900px] my-10">
              <div className="flex gap-3 items-start">
                <div className="overflow-hidden rounded relative flex-[0.20] min-w-[120px]">
                  <img
                    src={eduData.classInfo.img}
                    alt=""
                    className="w-full h-full object-cover object-bottom"
                  />
                </div>
                <div className="flex-[0.80] w-[100px]">
                  <div className="flex gap-2 items-center">
                    <div className="px-3">
                      <p className="text-2xl leading-[0.8]">
                        {eduData.info.title}
                      </p>
                      <span className="text-xs opacity-50">
                        {eduData.info.etc.filter((x) => x !== "").join(" | ")}
                      </span>
                    </div>
                    {eduData.classInfo.state === "마감임박" && (
                      <div className="px-1 text-xs rounded-sm bg-red-500 text-white whitespace-nowrap">
                        마감임박
                      </div>
                    )}
                    {eduData.classInfo.state === "마감" && (
                      <div className="px-1 text-xs rounded-sm bg-gray-500 text-white whitespace-nowrap">
                        마감
                      </div>
                    )}
                  </div>

                  <div className="mb-3 px-3">
                    {eduData.info.star.length === 0 || (
                      <div className="flex gap-1">
                        <p className="text-sm opacity-50">
                          {eduData.info.star.rate}
                        </p>
                        <StarRating rating={eduData.info.star.rate} size="15" />
                        <p className="text-xs opacity-50">
                          ({eduData.info.star.length})
                        </p>
                      </div>
                    )}
                    {eduData.info.review_count !== 0 && (
                      <p className="text-sm opacity-50">
                        등록된 리뷰 {eduData.info.review_count}개
                      </p>
                    )}
                  </div>

                  <div className="text-sm">
                    <div className="flex items-center gap-2">
                      <img
                        alt=""
                        src={require("./calendar.svg")}
                        className="w-4 opacity-50"
                      />
                      <p>{eduData.classInfo.sdate}</p>
                      <div className="px-1 text-xs rounded-sm bg-green-400 text-white">
                        D-
                        {moment(eduData.classInfo.sdate).diff(
                          moment(),
                          "days"
                        ) + 1}
                      </div>
                    </div>
                    <div className="ml-6 leading-[100%] opacity-50 mb-2">
                      <div className="flex gap-1">
                        {eduData.classInfo.yoil
                          .filter((x) => x !== "")
                          .map((x) => x + "요일")
                          .join(", ")}
                      </div>
                      <p>
                        {eduData.classInfo.sdate} ~ {eduData.classInfo.edate}
                      </p>
                      <p>{eduData.classInfo.time}</p>
                    </div>
                    <div className="flex items-center gap-2 opacity-50">
                      <img
                        alt=""
                        src={require("./people.svg")}
                        className="w-4 opacity-50"
                      />
                      <p>{eduData.classInfo.quota}</p>
                    </div>
                    <div className="flex items-start gap-2">
                      <img
                        alt=""
                        src={require("./openbook.svg")}
                        className="w-4 opacity-50"
                      />
                      <p className="flex-1">온라인 이론시험, 현장 실기시험</p>
                    </div>
                    <div className="flex items-start gap-2">
                      <img
                        alt=""
                        src={require("./location.svg")}
                        className="w-4 opacity-50"
                      />
                      <p className="flex-1">
                        {eduData.classInfo.address},{" "}
                        {eduData.classInfo.location}
                      </p>
                    </div>
                    <div className="flex w-full mt-3">
                      <div className="p-4">
                        <Avatar
                          src={`https://api.teamstc.org:3003/api/img/trainerinfo/${eduData.classInfo.tcd}.jpg`}
                          sx={{ width: 50, height: 50 }}
                        />
                      </div>
                      <div className="flex-1 overflow-hidden">
                        <div className="flex items-end justify-between">
                          <p className="text-lg break-all">
                            {eduData.classInfo.tname}
                          </p>
                          {/* <button
                            className="text-sm underline opacity-50"
                            onClick={() =>
                              router.push(
                                "/user/menu/trainer/" + eduData.classInfo.tcd
                              )
                            }
                          >
                            자세히보기
                          </button> */}
                        </div>
                        {eduData.classInfo?.tstar?.length === 0 || (
                          <div className="flex items-center gap-1">
                            <p className="text-sm opacity-50">
                              {eduData.classInfo.tstar.rate}
                            </p>
                            <StarRating
                              rating={eduData.classInfo.tstar.rate}
                              size="15"
                            />
                            <p className="text-xs opacity-50">
                              ({eduData.classInfo.tstar.length})
                            </p>
                          </div>
                        )}
                        <div className="text-xs leading-[120%] opacity-50 w-full flex-1">
                          {eduData.classInfo.career.map((data, index) => (
                            <p className="truncate w-full" key={index}>
                              {data}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-4" />
              <div className="mb-3">
                <div className="flex justify-between text-sm">
                  <p className="opacity-50">결제금액</p>
                  <p>{eduData.classInfo.amount.toLocaleString()} 원</p>
                </div>
                {eduData.classInfo.early.end && (
                  <div className="flex justify-between text-sm">
                    <p className="opacity-50">할인</p>
                    <p>
                      <span className="opacity-50">
                        {eduData.classInfo.early.end} 까지{" "}
                        {eduData.classInfo.early.value}
                      </span>{" "}
                      - {eduData.classInfo.early.discount.toLocaleString()} 원
                    </p>
                  </div>
                )}
                <div className="flex justify-between text-sm items-end">
                  <p className="opacity-50">최종가격</p>
                  <p className="text-3xl font-bold">
                    {(
                      eduData.classInfo.amount -
                      eduData.classInfo.early.discount
                    ).toLocaleString()}{" "}
                    원
                  </p>
                </div>
              </div>

              <button
                className="w-full text-center py-5 my-3 bg-blue-500 text-white rounded text-lg"
                onClick={goDownloadApp}
              >
                카르페 앱에서 자세히보기
              </button>
            </div>
          </div>

          <img
            alt={eduData.info.img}
            src={eduData.info.img}
            className="w-full"
          />

          <img
            width="500"
            height="500"
            alt={eduData.info.img2}
            src={eduData.info.img2 + "?v=" + new Date().getTime()}
            className="w-full"
            onError={(e) => e.target.classList.add("hidden")}
          />

          {/* 리뷰 보기 */}
          {eduData.info.review_count !== 0 && (
            <div className="p-3 my-10">
              <div className="mb-2 flex justify-between">
                <p className="font-semibold opacity-80">
                  리뷰 ({eduData.info.review_count})
                </p>
                <button
                  className="opacity-50 underline text-sm"
                  onClick={goDownloadApp}
                >
                  카르페 앱에서 자세히보기
                </button>
              </div>
              <div className="flex flex-col gap-2">
                {eduData.reviews.map((review, index) => (
                  <div key={index} className="p-3 !border rounded">
                    <StarRating rating={review.point} />
                    <p className="text-xs opacity-50">
                      {moment(review.regdate).format("YYYY.MM.DD")}
                    </p>
                    <p className="line-clamp-3 text-sm opacity-80">
                      {review.comment}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
